import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import { Space } from 'antd';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Modal, Row, Col } from 'antd';
import { Button as AntButton } from 'antd';
// import { fontWeight } from '@mui/system';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { useSelector } from 'react-redux';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import {
    createBenefApi,
    fundTransferIserveuOnly,
    getDmtChargesApi,
    getMonthlyLimitApi,
    indexVerifySenderAPI,
    loadTransferApi,
    moneytransferBenefDetailsApi
} from 'utils/API/Merchant APIs/DmtApiCalls';
import { mpinTokenExtender } from 'utils/API/MerchantApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { useDispatch } from 'react-redux';

const commonStyles = {
    m: 0
};

export default function DenseTable() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isMerchantOrBc = useSelector((state) => state?.checkIsBC?.authorizationStatus);
    // const agentType = isMerchantOrBc?.agentType;
    // const pendingStatus = isMerchantOrBc?.isPending;
    //STATE MANAGEMENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //State to store mode
    const [paymentMode, setPaymentMode] = useState('IMPS');
    //Selected benef details state
    const [isBenefOnboarded, setIsBenefOnboarded] = useState(false);
    // console.log('isBenefOnboarded', isBenefOnboarded);
    const [selectedBenefData, setSelectedBenefData] = useState('');
    // console.log('selectedBenefData', selectedBenefData);
    //State to store response of amount-values Api
    const [amountResponse, setAmountResponse] = useState('');
    const [amountError, setAmountError] = useState('');
    // console.log('amountResponse', amountResponse);
    //Dropdown helpers
    const [customer, setcustomer] = useState('');
    // console.log('customer in state now : ', customer);
    const [beneficiary, setBeneficiary] = useState('');
    // console.log('beneficiary in state :', beneficiary);

    //Arrays to map
    const [customerMap, setCustomerMap] = useState([]);
    // console.log('customerMap', customerMap);
    const [beneficiaryMap, setBeneficiaryMap] = useState([]);
    // console.log('beneficiaryMap : ', beneficiaryMap);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    // console.log('selectedCustomer', selectedCustomer);
    const [choosedBenef, setChoosedBenef] = useState(null);

    //State to store amount
    const [tamount, setTamount] = useState();
    //Is loading state for button change
    const [isLoading, setIsLoading] = useState(false);
    const [maxLimitLoading, setMaxLimitLoading] = useState(false);
    const [senderno, setSenderno] = useState('');
    // console.log('senderno in state :', senderno);
    const [maxLimit, setMaxLimit] = useState(0);
    //State to check the transfer is success
    const isSuccess = true;
    const isFirstRender = true;
    const [isTrue, setIsTrue] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isFailedMessage, setIsFailedMessage] = useState('');
    // const [customerErrMessage, setCustomerErrMessage] = useState('');
    const [isTransfer, setIsTransfer] = useState(true);
    // const [merchantEmpID, setMerchantEmpID] = useState('');
    // console.log('isSuccess', isSuccess);
    const location = useLocation();
    const navigate = useNavigate();
    // const senderno = location.state.data.customermob;
    const CreateCustomerRes = location?.state?.Data;
    // console.log('location data', location.state);
    const transfer = 'iserveu';
    const id = location?.state?.data?.id || location?.state?.data?.data[0]?.id;
    // console.log('location id : ', id);
    // const index = location?.state?.index;
    const indexBenef = location?.state?.indexBenef;
    // console.log('location state : ', location.state.row.benefId);
    const benefId = location?.state?.row?.benefId;
    const uid = localStorage.getItem('id');
    const email = localStorage.getItem('email_id');
    const data = location?.state?.item || location?.state?.data;
    // console.log('location.state', location?.state);
    const type = 'fino';
    const benAcc = selectedBenefData?.beneAccountNo;
    const benIfsc = selectedBenefData?.beneIfsc;
    const beneMobileNo = selectedBenefData?.beneMobile;
    const amount = amountResponse?.amount;
    // const servicecharge = 0;
    // const beneName = selectedBenefData?.beneName;
    // const Sdist_commission = amountResponse?.sdist_commission;
    // const Dist_commission = amountResponse?.dist_commission;
    // const Agent_commission = amountResponse?.agent_commission;
    // const Agst = amountResponse?.gst;
    // const Atds = amountResponse?.tds;
    // const Tservice_charge = amountResponse?.service_charge;
    // const TnewserviceCharge = amountResponse?.newserviceCharge;
    // const txn_agentDebitAmount = amountResponse?.agentDebitAmount;
    // const agent = localStorage.getItem('id');
    // const isEmployee = localStorage.getItem('isEmployee');
    // const senderno = localStorage.getItem('sendernumber')
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //States and handlers for modal component
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);

    // const [modalText, setModalText] = useState('Content of the modal');

    //w1 balance
    const balanceW1 = useSelector((state) => state?.wallet?.balance?.roundedNumber);
    const handleTransferClick = () => {
        setIsFailedMessage('');
        setIsFailed(false);
        if (tamount >= 100) {
            // showModal();
            fetchValues();
        } else {
            setAmountError('The minimum transfer amount is 100!');
        }
    };
    const showModal = () => {
        // console.log('customer && beneficiary && amount', customer, beneficiary, amount);
        if (customer && beneficiary && tamount) {
            setOpen(true);
        } else {
            // console.log('show modal else !');
            // setIsFailedMessage('Make sure you have filled all the fields');
            globalNotifyError('Make sure you have filled all the fields!');
            // console.log('Is failed should be false :', isFailed);

            // setIsFailed(true);
            // console.log('is failed? :', isFailed);

            setIsLoading(false);
        }
    };
    const handleOk = () => {
        // console.log('ok');
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        transferMoney();
    };
    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpen(false);
        dispatch(setDisablerFalse());
    };
    //refresh token api call ...................................................................................................
    const fetchRefreshToken = async () => {
        const agentid = localStorage.getItem('user_id');
        const payload = {
            agentid
        };
        const response = await mpinTokenExtender(payload);
        localStorage.setItem('token', response?.data?.refresh_token);
    };
    //..........................................................................................................................

    //STATE MANAGEMENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //Functions to API calls>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const customerDetails = async () => {
        try {
            const payload = { uid, email, senderno, type };
            const response = await moneytransferBenefDetailsApi(payload);
            const payloadIndex = { uid };
            const customerApi = await indexVerifySenderAPI(payloadIndex);
            const customerList = customerApi?.data?.data?.customersList;
            const benefData = response?.data?.data?.benfDetails;
            setCustomerMap(customerList);
            setBeneficiaryMap(benefData);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Api to fetch values when amount entered
    const fetchValues = async () => {
        // console.log('fetch values called');
        setIsTrue(true);
        try {
            const payload = { uid, transfer, paymentMode, tamount };
            const values = await getDmtChargesApi(payload);
            // console.log('values', values);
            setAmountResponse(values?.data);
            showModal();
            setIsTrue(false);
            showModal();
        } catch (error) {
            console.log(error.message);
            setIsTrue(false);
            handleCatchError(error);
        }
    };

    //Api to fetch data of single beneficiary on change of dropdown
    const fetchBenefDetails = async () => {
        const type = transfer;
        const id = beneficiary;
        try {
            const payload = { email, type, id, senderno };
            const singleBenefData = await loadTransferApi(payload);
            // console.log('singleBenefData', singleBenefData);
            const detailsOfBenef = singleBenefData?.data?.data;
            setSelectedBenefData(detailsOfBenef);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Api to tranfer on click of button>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const transferMoney = async () => {
        // console.log('clicked');
        setIsLoading(true);
        setIsFailed(false);
        setIsFailedMessage('');
        // const token = localStorage.getItem('token');
        try {
            const beneficiaryId = selectedBenefData?.id;
            const customerId = selectedCustomer?.id;
            if (customer && beneficiary && amount) {
                setIsFailed(false);
                const payload = {
                    transfer,
                    uid,
                    email,
                    senderno,
                    benAcc,
                    benIfsc,
                    beneMobileNo,
                    paymentMode,
                    amount,
                    beneficiaryId,
                    customerId
                };
                const transferResponse = await fundTransferIserveuOnly(payload);
                const detailsOfTransfer = transferResponse;
                // console.log('detailsOfTransfer', detailsOfTransfer);
                const apistatus = detailsOfTransfer?.data?.status;
                const orderId = detailsOfTransfer?.data?.data?.orderId;
                // console.log(orderId);
                // console.log('apistatus', apistatus);
                setOpen(false);
                dispatch(setDisablerFalse());
                setConfirmLoading(false);
                if (apistatus) {
                    setIsTransfer(true);
                    navigate('/dmt/dmtreciept', { state: { data, orderId, isTransfer } });
                    // console.log(orderId);
                    // window.location.reload();
                } else {
                    // setIsFailed(true);
                    // setIsFailedMessage(detailsOfTransfer?.data?.message);
                    // CommonErrorNotify(detailsOfTransfer?.data?.message);
                    globalNotifyError(detailsOfTransfer?.data?.message);
                }
                setIsLoading(false);
            } else {
                // console.log('in else');
                setIsFailedMessage('Make sure you have filled all the fields');
                setIsFailed(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //UPDATE BENEFICIARY AS PER THE SELECTED CUSTOMER
    const updateBeneficiary = async () => {
        try {
            const payload = { uid, email, senderno, type };
            const response = await moneytransferBenefDetailsApi(payload);
            // console.log('moneytransfer_dmt response 222', response);
            const benefData = response?.data?.data?.benfDetails;
            setBeneficiaryMap(benefData);
            // console.log('beneficiaryMap', beneficiaryMap);
        } catch (error) {
            console.log(error);
            handleCatchError(error);
        }
    };
    const checkMerchantBC = async () => {
        //CHECKING FOR  MERCHANT OR BC
        setIsCheckLoading(true);
        const agentid = localStorage.getItem('id');

        const payloadCheck = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
        setIsCheckLoading(false);
        // const globalMerchantPermission = useSelector((state) => state);
        // console.log('globalMerchantPermission', globalMerchantPermission);
        const agentType = merchantBcCheckResponse?.data?.agentType;
        const pendingStatus = merchantBcCheckResponse?.data?.isPending;
        setIsBcConvertPending(pendingStatus);

        if (agentType === 'Merchant' || pendingStatus) {
            setMerchantModalOpen(true);

            return;
        }
        //CHECKING ENDS HERE
    };
    const getTransactionLimit = async () => {
        // console.log('agent senderno type :', agent, senderno, type);
        try {
            setMaxLimitLoading(true);
            const customerId = data?.id || data.data[0].id;
            const type = localStorage.getItem('dmtType');
            const payload = { customerId, senderno, type };
            const response = await getMonthlyLimitApi(payload);
            // console.log('Limit response : ', response);

            //This if condition is tempor
            // if (response?.data?.status) {
            // console.log('status trueee', response.status);
            const maximumLimit = response?.data?.data?.limit;
            if (maximumLimit >= 0) {
                setMaxLimitLoading(false);
                setMaxLimit(maximumLimit);
            } else {
                setMaxLimitLoading(false);
                setMaxLimit(0);
            }
            // } else {
            //     console.log('status false');
            //     setCustomerErrMessage(response.message);
            //     customerError();
            // }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    const CreateBenefToIserveu = async (row) => {
        // console.log('roww in create benefName:', row);
        //API TO ONBOARD
        const benefname = row?.benefName;
        const benelname = row?.benefLname;
        const AccountNo = row?.benefAcc;
        const ifsc = row?.benefIfsc;
        const MobileNo = row?.benefMobile;
        const senderId = row?.id;
        const transfername = localStorage.getItem('dmtType');
        try {
            const payload = { senderno, benefname, benelname, AccountNo, ifsc, MobileNo, senderId, transfername };
            const response = await createBenefApi(payload);
            // console.log('onboardddd response : ', response);
            if (response?.status === 201) {
                // console.log('201');
                setIsBenefOnboarded(false);
                const message = response?.data?.message;
                // CommonErrorNotify(message);
                globalNotifyError(message);
            } else if (response?.status === 200) {
                // console.log('200');
                setIsBenefOnboarded(true);
                setBeneficiary(row?.id);
                setChoosedBenef(row);
            } else {
                // console.log('ELSE 200201');
                setIsBenefOnboarded(false);
                // CommonErrorNotify('Transfer request failed, please try again.');
                globalNotifyError('Transfer request failed, please try again.');
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //FUNCTION TO FETCH SENDER NUMBER ON CUSTOMER CHANGE
    //EVENT HANDLERS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleModeChange = (event) => {
        event.preventDefault();
        setPaymentMode(event.target.value);
    };
    const handleBeneficiaryChange = (event, newValue) => {
        // console.log('benef newValue :', newValue);
        if (newValue?.benefId) {
            // console.log('ALREADY ONBOARDED');
            setBeneficiary(newValue?.id);
            setChoosedBenef(newValue);
            setIsBenefOnboarded(true);
        } else {
            // console.log('NOT ALREADY ONBOARDED');
            setIsBenefOnboarded(false);
            CreateBenefToIserveu(newValue);
        }
    };
    const handleAmountChange = (e) => {
        setAmountError('');
        const amountValue = e?.target?.value;
        const amountValueFloat = parseFloat(amountValue);
        const balanceW1Float = parseFloat(balanceW1);
        // console.log('amountValue', typeof amountValueFloat, 'balanceW1', typeof balanceW1Float);
        if (amountValueFloat > 0) {
            if (amountValueFloat <= balanceW1Float) {
                // console.log('amount value less');
                setTamount(amountValue);
            } else {
                // console.log('The entered amount exceeds the maximum limit.');
                setAmountError('Entered amount exceeds the wallet balance!');
            }
        } else {
            setAmountError('Please enter a valid amount.');
        }
        if (amountValueFloat > 0) {
            if (amountValue <= maxLimit) {
                // console.log('okay');
                setTamount(amountValue);
            } else {
                // console.log('The entered amount exceeds the maximum limit.');
                setAmountError('The entered amount exceeds the maximum limit');
            }
        } else {
            setAmountError('Please enter a valid amount.');
        }
    };

    const handleAddBenefClick = () => {
        navigate('/dmt/Checkbeneficiary', { state: { data: selectedCustomer } });
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    // useEffect(() => {
    //     if (agentType === 'Merchant' || pendingStatus) {
    //         setMerchantModalOpen(true);
    //     }
    //     setIsBcConvertPending(pendingStatus);
    //     if (isMerchantOrBc) {
    //         setIsCheckLoading(false);
    //     } else {
    //         setIsCheckLoading(true);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isMerchantOrBc]);
    useEffect(() => {
        if (open) {
            dispatch(setDisablerTrue());
        }
        // eslint-disable-next-line
    }, [open]);
    useEffect(() => {
        if (benefId) {
            setIsBenefOnboarded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [benefId]);

    useEffect(() => {
        // setBeneficiaryMap();
        // setSenderno();
        if (senderno) {
            customerDetails().then(updateBeneficiary).then(fetchRefreshToken);
            // getTransactionLimit();
            customerDetails();
            updateBeneficiary();
            // getTransactionLimit();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    //calling  transaction limit api to reassurance of loading of transaction limit>>>>>>>>>
    useEffect(() => {
        if (senderno) {
            getTransactionLimit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderno, customer]);

    useEffect(() => {
        // if (isEmployee === 'true') {
        //     const merchantEmpID = localStorage.getItem('empId');
        //     setMerchantEmpID(merchantEmpID);
        // }
        checkMerchantBC();
        if (CreateCustomerRes) {
            // customerNotify();
            globalNotifySuccess('Customer created successfully');
        }
        const allCustomers = location?.state?.filteredData;
        const filteredCustomer = allCustomers?.filter((data) => data?.id === id);
        if (filteredCustomer?.length > 0) {
            // console.log('');
            const number = filteredCustomer[0]?.customermob;
            // console.log('senderno 2', number);
            setSenderno(number);
        } else {
            // console.log('no length');
        }
        // console.log('location?.state?.senderno', location?.state?.senderno);
        if (location?.state?.senderno) {
            setSenderno(location?.state?.senderno);
        }
        setcustomer(id);
        const beneficiaryFromNavigate = location?.state?.row ? location?.state?.row?.id : '';
        setBeneficiary(beneficiaryFromNavigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tamount >= 100) {
            // fetchValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tamount]);
    useEffect(() => {
        if (beneficiary) {
            fetchBenefDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiary]);
    useEffect(() => {
        // console.log('in useEffect');
        if (isFailed) {
            // console.log('in if of useEffect');
            // notify();
            globalNotifyError(isFailedMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && customerMap && customerMap?.length > 0) {
            const filteredSingleCustomer = customerMap?.filter((item) => item?.id == id);
            // console.log('filteredSingleCustomer', filteredSingleCustomer);
            setSelectedCustomer(filteredSingleCustomer[0]); // Select the default option
        }
        if (location?.state?.data) {
            // console.log('location?.state?.data updateee', location?.state?.data);
            // const customerData = location?.state?.data?.data[0] || location?.state?.data;
            let customerData;
            if (location?.state?.data?.data) {
                customerData = location?.state?.data?.data[0];
            } else {
                customerData = location?.state?.data;
            }
            // console.log('customerData', customerData);
            setSelectedCustomer(customerData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerMap]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && beneficiaryMap && beneficiaryMap.length > 0) {
            beneficiaryMap?.map((item) => {
                if (item.id === indexBenef) {
                    setChoosedBenef(item); // Select the default option
                }
            });
            // setChoosedBenef(beneficiaryMap[indexBenef]); // Select the default option
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryMap]);

    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    // flex: Grid,
                    borderRadius: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2,
                        marginBottom: 2
                    }
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={6} sm={6} xs={12}>
                        <Typography variant="h3">
                            {' '}
                            <GlobalBackButton /> Domestic Money Transfer{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Transfer page</Typography>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Chip
                                color="info"
                                label={`Selected Customer: ${
                                    selectedCustomer ? (selectedCustomer?.name ? selectedCustomer.name : '') : ''
                                }${selectedCustomer?.lname ? ' ' + selectedCustomer.lname : ''}`}
                                sx={{ fontWeight: 'bold' }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {/* <Chip color="success" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} /> */}
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {maxLimitLoading ? (
                                <Chip color="warning" label="Loading..." sx={{}} />
                            ) : (
                                <Chip color="warning" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                    <ArrowBackIcon sx={{ mr: 1 }} />
                </Fab>
            </Box> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: isMobile ? '20px 10px' : 2,
                    boxShadow: 2,
                    borderRadius: 3
                }}
            >
                {/* <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Chip color="success" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} />
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>{senderno ? 'SENDER MOBILE : ' + senderno : 'SENDER MOBILE : ' + '-'}</h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneAccountNo
                                        ? 'BENEFICIARY A/C : ' + selectedBenefData?.beneAccountNo
                                        : 'BENEFICIARY A/C : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneIfsc
                                        ? 'BENEFICIARY IFSC : ' + selectedBenefData?.beneIfsc
                                        : 'BENEFICIARY IFSC : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneMobile
                                        ? 'BENEFICIARY Mobile : ' + selectedBenefData?.beneMobile
                                        : 'BENEFICIARY Mobile : ' + '-'}
                                </h5>
                            </Grid>

                            {/* <Stack style={{ paddingLeft: 100, paddingTop: 20 }} direction="row" spacing={2}> */}
                            {/* </Stack> */}
                            {/* <Stack style={{ paddingLeft: 100, paddingTop: 5 }} direction="row" spacing={2}> */}
                            {/* </Stack> */}

                            {/* <Stack style={{ paddingLeft: 70 }} direction="row" spacing={2}> */}

                            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 0 }}>
                                    </Box>
                                </Stack>
                            </Grid> */}

                            {/* </Stack> */}
                            {/* <Stack style={{ paddingLeft: 70 }} direction="row" spacing={2}> */}

                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    {/* <h3 style={{ paddingLeft: 5, paddingTop: 18 }}>Mode of Transfer: </h3> */}
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        <FormControl fullWidth style={{ minWidth: 200 }}>
                                            <InputLabel htmlFor="demo-simple-select-label">IMPS/NEFT</InputLabel>
                                            <Select
                                                labelid="demo-simple-select-label"
                                                id="demo-simple-select-label"
                                                value={paymentMode}
                                                label="mode"
                                                onChange={handleModeChange}
                                            >
                                                <MenuItem value="IMPS">IMPS</MenuItem>
                                                {/* <MenuItem value="NEFT">
                                                    NEFT
                                                </MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    {/* <h3 style={{ paddingTop: 18 }}>Select beneficiary: </h3> */}
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        {/* <FormControl fullWidth> */}
                                        {/* <InputLabel id="demo-simple-select-label">Beneficiary</InputLabel> */}
                                        <Autocomplete
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={choosedBenef}
                                            label="Beneficiary"
                                            onChange={handleBeneficiaryChange}
                                            options={beneficiaryMap ? beneficiaryMap : []}
                                            getOptionLabel={(option) =>
                                                option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    ? option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    : ''
                                            }
                                            renderInput={(params) => <TextField {...params} label="Beneficiary" />}
                                        />
                                        {/* {beneficiaryMap?.map((item) => (                        
                                                    <MenuItem value={item.benefId}>
                                                        {item.benefName + ' / ' + item.benefAcc?.replace(/\d(?=\d{4})/g, '*')}
                                                    </MenuItem>
                                                ))} */}
                                        {/* </Select> */}
                                        {/* </FormControl> */}
                                    </Box>
                                    <Box sx={{ pt: 3, '& > :not(style)': {} }}>
                                        <Tooltip title="Click to add new beneficiary">
                                            {/* <Link to="/dmt/Checkbeneficiary"> */}
                                            <AntButton onClick={handleAddBenefClick} shape="circle" type="primary" icon={<AddIcon />} />
                                            {/* </Link> */}
                                        </Tooltip>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack sx={{ pt: 2 }} direction="row">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    {/* <h3 style={{ paddingLeft: 0, paddingTop: 18 }}>Amount: </h3> */}

                                    <TextField
                                        id="outlined-basic"
                                        label="Amount"
                                        variant="outlined"
                                        error={Boolean(amountError)}
                                        helperText={amountError}
                                        onChange={handleAmountChange}
                                    />
                                    {isTrue ? (
                                        <Space
                                            direction="vertical"
                                            style={{
                                                width: '20%',
                                                paddingTop: 20
                                            }}
                                        >
                                            <Spin size="large">
                                                <div className="content" />
                                            </Spin>
                                        </Space>
                                    ) : null}
                                </Stack>
                            </Grid>

                            <h4 style={{ paddingLeft: 40, color: 'red' }}>{!isSuccess ? 'Money transfer failed' : null}</h4>
                            <Grid item lg={12} md={6} sm={6} xs={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={Boolean(amountError) || Boolean(isLoading) || Boolean(!isBenefOnboarded)}
                                    // onClick={transferMoney}
                                    onClick={handleTransferClick}
                                    fullWidth
                                >
                                    {isLoading ? 'Loading...' : 'Transfer'}
                                </Button>
                                <Modal
                                    title={<Chip color="info" label={'CONFIRM MONEY TRANSFER DETAILS '} sx={{}} />}
                                    open={open}
                                    onOk={handleOk}
                                    okText="Confirm and Pay"
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancel}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <p>Customer Name: {selectedCustomer?.name + ' ' + selectedCustomer?.lname}</p>
                                            <p>Date: {new Date().toDateString().split(' ').slice(1).join(' ')}</p>
                                            <p>Beneficiary A/C : {selectedBenefData ? selectedBenefData?.beneAccountNo : ''}</p>
                                            <p>Service Charge : {amountResponse ? amountResponse.newserviceCharge : ''}</p>
                                            <p>GST : {amountResponse ? amountResponse.gst : ''}</p>
                                            <p>TDS : {amountResponse ? amountResponse.tds : ''}</p>
                                        </Col>

                                        <Col span={12}>
                                            <p>Beneficiary Name: {selectedBenefData?.beneName}</p>
                                            <p>Payment Mode: {paymentMode}</p>
                                            <p>Beneficiary IFSC : {selectedBenefData ? selectedBenefData?.beneIfsc : ''}</p>
                                            <p>Effective Commission : {amountResponse ? amountResponse.agent_commission : ''}</p>
                                            <p>Total Commission : {amountResponse ? amountResponse.totCommi : ''}</p>
                                            <p>Total Debit Amount : {amountResponse ? amountResponse.agentDebitAmount : ''}</p>
                                            {/* <p style={{ fontWeight: 'bold' }}>
                                                Total Debit Amount: {amountResponse ? amountResponse.agentDebitAmount : ''}
                                            </p> */}
                                        </Col>
                                    </Row>
                                    <p style={{ fontWeight: 'bold' }}>
                                        Total Debit Amount: ₹ {amountResponse ? amountResponse.agentDebitAmount : ''}
                                    </p>
                                </Modal>
                            </Grid>
                            {/* </Stack> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading} />
        </>
    );
}
