import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
export const authenticationCommonPostRequest = async (apiEndPoint, payload) => {
    const endPoint = commonEndPoint + apiEndPoint;
    // console.log('tokennn', token, apiEndPoint);
    const token = localStorage.getItem('token');
    let merchantEmpID;
    const isEmployee = localStorage.getItem('isEmployee');
    if (isEmployee === 'true') {
        merchantEmpID = localStorage.getItem('empId');
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
const POST_API_ENDPOINTS_AUTH = {
    login: 'moneytransfer/login',
    twoFAuth: 'moneytransfer/VerifyOTP2Fauth',
    resend2FOTP: 'moneytransfer/ResendOTP2Fauth',
    domainImage: 'domain/image',
    resetPasswordStep1: 'verifyResetPasswordstep1',
    resetPasswordVerifyOTP: 'verifyOtpResetPassword',
    resetNewPassword: 'ResetConfirmPassword',
    // mpinStatusChecker: 'moneytransfer/merchant/StatusMpin',
    supportAuth: 'moneytransfer/support-auth',
    authLogin: 'authLogin',
    loginWithPhone: 'moneytransfer/loginWithPhone',
    loginWithPhoneVerifyOtp: 'moneytransfer/loginWithPhoneVerifyOtp '
};

export const loginRequest = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.login, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const loginWithPhoneRequest = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.loginWithPhone, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const loginWithPhoneVerifyOtpRequest = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.loginWithPhoneVerifyOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const authLoginRequest = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.authLogin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resend2FOTP = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.resend2FOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const verify2FAuth = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.twoFAuth, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getDomainImage = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.domainImage, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resetPasswordStep1 = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.resetPasswordStep1, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resetPasswordVerifyOTP = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.resetPasswordVerifyOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resetNewPassword = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.resetNewPassword, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

// export const mpinStatusChecker = async (payload) => {
//     const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.mpinStatusChecker, payload)
//         .then((response) => {
//             return response;
//         })
//         .catch((error) => {
//             console.log(error);
//             return null;
//         });
//     return response;
// };

export const supportAuthApi = async (payload) => {
    const response = await authenticationCommonPostRequest(POST_API_ENDPOINTS_AUTH.supportAuth, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
