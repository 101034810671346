/* eslint-disable */

import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UpdateProfileBc from 'views/Components/FormSubmissions/UpdateProfileBc';

//Antd and mui imports

import { Modal, Button } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { Checkbox } from 'antd';
import Chip from '@mui/material/Chip';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useState } from 'react';
import './modalStyles.css';
import samplePDF from '../../assets/Acemoney_BC_Agreement.pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffect } from 'react';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { CustomerServiceOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { Input } from 'antd';
import { Tooltip } from 'antd';
const { Search } = Input;
// import { Collapse } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { handleCatchError } from 'utils/CatchHandler';
import TextField from '@mui/material/TextField';
import Mpin from 'views/pages/authentication/M-pin/Mpin';
// import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { checkMerchantOrBc } from 'redux/ServiceRestrictSlice';
// import { supportAuthApi } from 'utils/API/Merchant APIs/AuthApiCalls';
import { logOutGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { getDomainImage, supportAuthApi } from 'utils/API/Merchant APIs/AuthApiCalls';
import {
    agreePolicyApi,
    checkPermissionGet,
    faqSearchApi,
    getDefaultFaq,
    updateBcAgentAccountApi,
    updateThemeMode
} from 'utils/API/Merchant APIs/MerchantApiCalls';
import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { BulbOutlined, BulbFilled } from '@ant-design/icons';
import { toggleTheme } from 'redux/themeSlice';
const commonStyles = {
    m: 0
};
import MyButton from '@mui/material/Button';
// import { setMpinTrue } from 'redux/MpinModalSlice';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, mode }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    }),
    background: mode === 'dark' ? 'linear-gradient(to bottom, #1f1f1f, #114568, #0c507d, #0071bc)' : ''
}));

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
// ==============================|| MAIN LAYOUT ||============================== //

//FAQ DATA INPUTS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;
// const items = [
//     {
//         key: '1',
//         label: 'This is panel header 1',
//         children: <p>{text}</p>
//     },
//     {
//         key: '2',
//         label: 'This is panel header 2',
//         children: <p>{text}</p>
//     },
//     {
//         key: '3',
//         label: 'This is panel header 3',
//         children: <p>{text}</p>
//     }
// ];

const MainLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bcAgreementStatus = localStorage.getItem('bcAgreementStatus');
    const userinfoCategory = localStorage.getItem('userinfoCategory');
    // console.log('MAIN LAYOUT RENDERED');
    const isEmployee = localStorage.getItem('isEmployee');
    // console.log('isEmployee in mainlayout', isEmployee);
    const [open, setOpen] = useState(false);
    // console.log('Agreement modal open?', open);
    const [bankModalOpen, setBankModalOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    // console.log('modal open', open);
    const [checked, setChecked] = useState(false);
    const [isCheckedError, setIsCheckedError] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [faqModalOpen, setFaqModalOpen] = useState(false);
    const [faqSearch, setFaqSearch] = useState('');
    const [faqData, setFaqData] = useState([]);
    const loginType = localStorage.getItem('type');
    const [searchTerm, setSearchTerm] = useState('');
    const [faqRes, setFaqRes] = useState([]);
    // const [isDarkMode, setIsDarkMode] = useState(false);

    const searchedFaqData = faqData.filter(
        (item) =>
            item?.question.toLocaleLowerCase().includes(faqSearch.toLocaleLowerCase()) ||
            item?.answer.toLocaleLowerCase().includes(faqSearch.toLocaleLowerCase())
    );
    // console.log('isBcBankVerified', isBcBankVerified);
    //STATES FOR ACCOUNT DETAILS ENTRY OF SELECTED CUSTOMERS>>>>>>>>>>>>>>
    const [bankAccountName, setBankAccountName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [confirmBankAccountNumber, setConfirmBankAccountNumber] = useState('');
    const [bankAccountIFSC, setBankAccountIFSC] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankBranchName, setBranchName] = useState('');
    const [panNumber, setPanNumber] = useState('');
    const [modalForSession, setModalForSession] = useState(false);
    const [timeLeft, setTimeLeft] = useState(10);
    const [isPremiumBc, setIsPremiumBc] = useState('');

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //CODE FOR CONTROLLING ACCORDION>>>>>>>>>>>>>>>>>>>>>>
    const [expanded, setExpanded] = useState(false);
    const [zinde, setZindex] = useState('');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleFaqModalOpen = async () => {
        dispatch(setDisablerTrue());
        const defaultFaqResponse = await getDefaultFaq();
        const data = defaultFaqResponse?.data?.data;
        setFaqRes(data);
        setFaqModalOpen(true);
        // getFaqData();
    };

    //HANDLER FUNCTIONS FOR  BANK DETAILS FORM>>>>>>>>>>>>>>.
    const [ifscError, setIfscError] = useState('');
    const [accError, setAccError] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [accNameError, setAccNameError] = useState('');
    const [panError, setPanError] = useState('');
    const [bankNameError, setBankNameError] = useState('');
    const [branchError, setBranchError] = useState('');
    const [isLoadingBankUpdate, setIsLoadingBankUpdate] = useState(false);
    const [checkMpinStatus, setCheckMpinStatus] = useState(false);
    // const mpinModalState = useSelector((state) => state?.mpinModalState?.yourStateMpin);
    // console.log('mpinModalState', mpinModalState);
    const menuBarDisabler = useSelector((state) => state?.disabler?.sidebarShow);
    useEffect(() => {
        if (modalForSession) {
            // Exit if timeLeft is 0
            if (!timeLeft) return logoutFunction();

            // Create interval
            const timerInterval = setInterval(() => {
                // Decrease time left by 1 second
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 1000);

            // Cleanup function
            return () => clearInterval(timerInterval);
        }
    }, [timeLeft, modalForSession]);

    const messageExp = localStorage.getItem('tokenMessage');
    const handlePasswordExpValidation = () => {
        const passwordTokenExp = localStorage.getItem('passwordTokenExp');
        if (passwordTokenExp === 'true') {
            setModalForSession(true);
            dispatch(setDisablerTrue());
        } else {
            setModalForSession(false);
            if (passwordTokenExp === 'true') {
                dispatch(setDisablerFalse());
            }
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            handlePasswordExpValidation();
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const logoutFunction = async () => {
        try {
            const response = await logOutGet();
            if (response.data.success) {
                localStorage.removeItem('token');
                localStorage.clear();
                navigate('/');
                window.location.reload();
            } else {
                window.location.reload();
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    const handleOk = () => {
        logoutFunction();
    };
    const handleMpinStatus = () => {
        const mpinModalState = localStorage.getItem('mpinStatus');
        if (mpinModalState === 'true') {
            setCheckMpinStatus(true);
        } else {
            setCheckMpinStatus(false);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            handleMpinStatus();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    // const mpinModalState = localStorage.getItem('mpinModal');
    const mode = useSelector((state) => state.theme.mode);
    useEffect(() => {
        const logoFromDomainName = async (domain) => {
            try {
                const payload = { domain };
                const response = await getDomainImage(payload);
                const logoURL = response?.data?.image_url;
                const iconImage = response?.data?.icon_image;
                if (logoURL) {
                    // setURL(logoURL);

                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                    if (window.location.hostname === 'www.seqpay.biz') {
                        // document.title = 'SeqPay';
                        document.title = window.location.hostname;
                        link.href = 'https://res.cloudinary.com/dlpcrfqha/image/upload/v1686290450/seqpay_c8m2mt.png';
                    } else if (window.location.hostname === 'app.digistorepay.com') {
                        // document.title = 'Digistorepay';
                        document.title = window.location.hostname;
                        link.href = 'https://res.cloudinary.com/dlpcrfqha/image/upload/v1703671458/digi_icon_dqxsd4.png';
                    } else if (window.location.hostname === 'app.acemoney.in') {
                        document.title = 'Acemoney';
                        link.href = 'https://aceneobank.com/assets/titlelogo.png';
                    } else if (window.location.hostname === 'dev-app.acemoney.in') {
                        document.title = 'Dev-Acemoney';
                        link.href = iconImage;
                    } else {
                        document.title = window.location.hostname;
                        link.href = iconImage;
                    }
                } else {
                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                    document.title = 'Acemoney';
                    link.href = 'https://aceneobank.com/assets/titlelogo.png';
                    // setURL(Acemoneylogo);
                }
            } catch (error) {
                console.log(error);
                handleCatchError(error);
            }
        };
        const domainName = window.location.protocol + '//' + window.location.hostname;
        if (window.location.hostname === 'app.acemoney.in') {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            document.title = 'Acemoney';
            link.href = 'https://aceneobank.com/assets/titlelogo.png';
        } else {
            logoFromDomainName(domainName);
        }
    }, []);
    useEffect(() => {
        if (menuBarDisabler) {
            setZindex(0);
        } else {
            setZindex('');
        }
    }, [menuBarDisabler]);
    useEffect(() => {
        if (open || bankModalOpen || faqModalOpen) {
            dispatch(setDisablerTrue());
        }
        if (!faqModalOpen) {
            dispatch(setDisablerFalse());
        }
        // eslint-disable-next-line
    }, [open, bankModalOpen, faqModalOpen]);
    useEffect(() => {
        // if (
        //     userinfoCategory === 'Premium BC Class 1' ||
        //     userinfoCategory === 'Premium BC Class 2' ||
        //     userinfoCategory === 'Premium BC Class 3'
        // ) {
        //     setIsPremiumBc(true);
        // } else {
        //     setIsPremiumBc(false);
        // }
        // Clear the fields on page load
        setBankName('');
        setBankAccountNumber('');
        setBankAccountName('');
        setBranchName('');
        setPanNumber('');
        setConfirmBankAccountNumber('');
        setBankAccountIFSC('');
    }, []);
    const handleConfirmACNumChange = (e) => {
        const text = e.target.value;
        setConfirmBankAccountNumber(text);
    };
    const handleIfscChange = (e) => {
        const text = e.target.value;
        const uppercaseText = text.toUpperCase();
        setBankAccountIFSC(uppercaseText);
    };
    const handlePanChange = (e) => {
        const text = e.target.value;
        const uppercaseText = text.toUpperCase();
        setPanNumber(uppercaseText);
    };
    const validateIfsc = () => {
        const re = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
        if (!re.test(bankAccountIFSC)) {
            setIfscError('Please enter a valid IFSC code');
        } else {
            setIfscError('');
        }
    };
    const validatePan = () => {
        const re = /[A-Z]{5}[0-9]{4}[A-Z]{1}/; // Regular expression to match 10 digit mobile number
        if (!re.test(panNumber)) {
            setPanError('Please enter a valid pan number');
        } else {
            setPanError('');
        }
    };

    const validateAccount = () => {
        // const re = /^[0-9]{9,18}$/; // Regular expression to match 10 digit mobile number
        if (!bankAccountNumber) {
            setAccError('Please enter a valid account number');
        } else {
            setAccError('');
        }
    };
    const validateBranchName = () => {
        // const re = /^[0-9]{9,18}$/; // Regular expression to match 10 digit mobile number
        if (!bankBranchName) {
            setBranchError('Please enter a valid branch name');
        } else {
            setBranchError('');
        }
    };
    const validateBankName = () => {
        if (!bankName) {
            setBankNameError('Please enter a valid bank name');
        } else {
            setBankNameError('');
        }
    };
    const validateAccountName = () => {
        // const re = /^[0-9]{9,18}$/; // Regular expression to match 10 digit mobile number
        if (!bankAccountName) {
            setAccNameError('Please enter a valid Account Name');
        } else {
            setAccNameError('');
        }
    };
    const validateChangeAccount = () => {
        if (bankAccountNumber == confirmBankAccountNumber) {
            setConfirmError('');
        } else {
            setConfirmError('Confirm account number and account number not matching!');
        }
    };

    const handleAccountChange = (e) => {
        setBankAccountNumber(e.target.value);
    };
    const handleAccNameChange = (e) => {
        setBankAccountName(e.target.value);
    };
    const handleBankNameChange = (e) => {
        setBankName(e.target.value);
    };
    const handleBankBranchName = (e) => {
        setBranchName(e.target.value);
    };

    const handleVerifyClick = () => {
        if (
            panNumber &&
            bankBranchName &&
            bankName &&
            bankAccountIFSC &&
            confirmBankAccountNumber &&
            bankAccountNumber &&
            bankAccountName &&
            !ifscError &&
            !accError &&
            !confirmError &&
            !accNameError &&
            !panError &&
            !bankNameError &&
            !branchError
        ) {
            updateBankDetails();
        } else {
            globalNotifyError('Please fill all the fields with valid details to continue.');
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // const bcAgreement = 'https://aceware-commons-public.s3.ap-south-1.amazonaws.com/Acemoney_BC_Agreement.pdf';
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const getSupportToken = async () => {
        // console.log('api function');
        // setRaiseLoading(true);
        const id = localStorage.getItem('id');
        try {
            const payload = { id };
            const response = await supportAuthApi(payload);
            // console.log('support auth response:', response);
            // setRaiseLoading(false);
            // navigate('/merchant-home');

            if (response?.data?.success) {
                const sup = response?.data?.data;
                const platform = response?.data?.platform;
                localStorage.setItem('supportToken', sup);
                localStorage.setItem('platform', platform);
                // if (message) {
                //     globalNotifySuccess(message);
                // }
            } else {
                const message = response?.data?.message;
                if (message) {
                    globalNotifyError(message);
                }
                if (response?.data?.errors) {
                    const errors = response?.data?.errors;
                    // Get an array of all values from the errors object
                    const errorArrays = Object.values(errors);
                    // Access the first array by default
                    const firstArray = errorArrays[0];
                    // console.log('firstArray', firstArray);
                    globalNotifyError(firstArray[0]);
                }
            }
        } catch (error) {
            // setRaiseLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const AgreementOkClick = async () => {
        // console.log('first');
        const token = localStorage.getItem('token');
        try {
            setIsLoading(true);
            const terms = 'on';

            const payload = { terms };
            const response = await agreePolicyApi(payload);
            // console.log('Agreement response :', response);
            if (response?.data?.status) {
                localStorage.setItem('bcAgreementStatus', true);
                globalNotifySuccess('Thank you for agreeing to the terms and conditions.');
                setOpen(false);
                // dispatch(setDisablerFalse());
            } else {
                globalNotifyError('Something went wrong please try again.');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
        }
    };
    //faq api
    const faqFetchApi = async (searchQuery) => {
        const token = localStorage.getItem('token');
        const searchText = searchQuery;
        try {
            setIsLoading(true);
            const payload = { searchText };
            const response = await faqSearchApi(payload);
            const data = response?.data?.data;
            setFaqRes(data);
        } catch (error) {
            console.log(error.message);
        }
    };
    const debounceSearch = debounce(faqFetchApi, 300); // 300ms debounce delay

    const handleInputChange = (event) => {
        const query = event.target.value;
        setSearchTerm(query);
        if (query) {
            debounceSearch(query);
        }
    };
    //API TO UPDATE BANK DETAILS>>>>>>>>>>>
    const updateBankDetails = async () => {
        try {
            setIsLoadingBankUpdate(true);
            const pancard = panNumber;
            const account_number = bankAccountNumber;
            const account_number_confirmation = confirmBankAccountNumber;
            const account_name = bankAccountName;
            const bank_name = bankName;
            const branch = bankBranchName;
            const ifsc = bankAccountIFSC;
            const payload = { pancard, account_number, account_number_confirmation, account_name, bank_name, branch, ifsc };
            const response = await updateBcAgentAccountApi(payload);
            if (response?.status === 200) {
                handleCheckPermission();
            } else {
                setIsLoadingBankUpdate(false);
            }
        } catch (error) {
            setIsLoadingBankUpdate(false);
            console.log(error.message);
        }
    };

    const handleCheckPermission = async () => {
        try {
            const response = await checkPermissionGet();
            const type = localStorage.getItem('type');
            if (response?.data?.bc_verification) {
                setIsLoadingBankUpdate(false);
                localStorage.setItem('bcBankVerified', true);
                setBankModalOpen(false);
                dispatch(setDisablerFalse());
                if (bcAgreementStatus === 'true' || type !== 'merchants') {
                    setOpen(false);
                    dispatch(setDisablerFalse());
                } else {
                    setOpen(true);
                }
                globalNotifySuccess('Bank details updated successfully!');
            } else {
                setIsLoadingBankUpdate(false);
                setBankModalOpen(true);
                globalNotifyError('Bank details updation failed. Please try again!');
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Modal helper functions
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        // setPageNumber(numPages);
        // console.log('numPages', numPages);
    }
    const handleCheck = (e) => {
        // console.log('checked', e.target.checked);
        setChecked(e.target.checked);
        setIsCheckedError(false);
    };
    const handleAgree = () => {
        if (checked) {
            AgreementOkClick();
        } else {
            setIsCheckedError(true);
        }
    };
    // const onChange = (key) => {
    //     console.log(key);
    // };
    const handleMainThemeChange = async (currentMode) => {
        let dark_mode;
        if (currentMode === 'light') {
            localStorage.setItem('mainThemeMode', 'dark');
            dark_mode = 1;
        } else {
            localStorage.setItem('mainThemeMode', 'light');
            dark_mode = 0;
        }
        // setIsDarkMode(!currentMode);
        dispatch(toggleTheme());
        const payload = { dark_mode };
        await updateThemeMode(payload);
    };
    //Useffects to control renders
    useEffect(() => {
        const checkpermissionCall = async () => {
            const response = await checkPermissionGet();
            const isPasswordValid = response?.data?.passwordresetTag;
            const agentType = response?.data?.agentType;
            if (agentType === 'Premium BC Class 1' || agentType === 'Premium BC Class 2' || agentType === 'Premium BC Class 3') {
                localStorage.setItem('premiumNotifyDontShow', true);
                setIsPremiumBc(true);
                const themeInLocalStorage = localStorage.getItem('mainThemeMode');

                if (themeInLocalStorage === 'dark' && mode === 'light') {
                    dispatch(toggleTheme());
                }
            } else {
                setIsPremiumBc(false);
                if (mode === 'dark') {
                    dispatch(toggleTheme());
                }
            }
            localStorage.setItem('isPasswordValid', isPasswordValid);
            // if (!isPasswordValid) {
            //     navigate('/merchants/mandatory-password-reset');
            // }
        };

        const type = localStorage.getItem('type');
        dispatch(checkMerchantOrBc());

        if (type === 'merchants') {
            getSupportToken();
        }

        const isBcBankVerified = localStorage.getItem('bcBankVerified');

        if (isBcBankVerified === 'false') {
            setBankModalOpen(true);
            navigate('/merchants/primary-profile-update');
        } else {
            if (type === 'merchants') {
                checkpermissionCall();
            } else {
                // const isPasswordValid = localStorage.getItem('isPasswordValid');
                // if (!isPasswordValid) {
                //     navigate('/merchants/mandatory-password-reset');
                // }
            }
            setBankModalOpen(false);
            dispatch(setDisablerFalse());
            if (bcAgreementStatus === 'true' || type !== 'merchants') {
                setOpen(false);
                dispatch(setDisablerFalse());
            } else {
                setOpen(true);
            }
        }
    }, []);
    function debounce(func, delay) {
        let timerId;
        return function (...args) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    return (
        <Box sx={{ display: 'flex', background: mode === 'dark' ? 'linear-gradient(to bottom, #1f1f1f, #114568, #0c507d, #0071bc)' : '' }}>
            {checkMpinStatus && <Mpin status={checkMpinStatus} />}
            <Modal
                title={<Chip color="info" label={'AGREEMENT '} sx={{}} />}
                className="custom-modal"
                wrapClassName="custom-modal-backdrop"
                centered
                open={open}
                // onOk={() => setOpen(false)}
                // onCancel={() => setOpen(false)}
                width={900}
                footer={null}
                closable={false}
                maskClosable={false}
            >
                {/* <PerfectScrollbar style={{ height: '100%', overflowX: 'scroll' }}> */}
                <div style={{ display: 'flex', justifyContent: 'center', height: '500px', overflowY: 'scroll' }}>
                    <Document file={samplePDF} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={index} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
                {/* <p>
                    Page {pageNumber} of {numPages}
                </p> */}
                {/* </PerfectScrollbar> */}
                {isCheckedError && <span style={{ color: 'red' }}>Please accept the terms and conditions</span>}
                <div className="modal-footer">
                    <Checkbox checked={checked} onChange={handleCheck}>
                        I accept the terms and conditions
                    </Checkbox>
                    <Button onClick={handleAgree} type="primary" loading={isLoading}>
                        Agree
                    </Button>
                </div>
            </Modal>
            {isPremiumBc && (
                <Tooltip placement="left" title={` Switch to ${mode === 'light' ? 'Dark' : 'Light'} Mode`}>
                    <FloatButton
                        shape="square"
                        type="default"
                        description={`${mode === 'dark' ? 'Light' : 'Dark'} mode`}
                        style={{
                            right: 24,
                            bottom: 240,
                            width: '60px',
                            height: '60px'
                        }}
                        onClick={() => handleMainThemeChange(mode)}
                        icon={mode === 'dark' ? <BulbOutlined /> : <BulbFilled />}
                    />
                </Tooltip>
            )}
            {loginType === 'merchants' && (
                <Tooltip placement="left" title="Frequently Asked Questions(FAQ)">
                    <FloatButton
                        type="primary"
                        description="FAQ"
                        shape="square"
                        style={{
                            right: 24,
                            bottom: 90,
                            width: '60px',
                            height: '60px'
                        }}
                        onClick={handleFaqModalOpen}
                        icon={<QuestionCircleOutlined />}
                    />
                </Tooltip>
            )}
            {loginType === 'merchants' && (
                <Tooltip placement="left" title="Go to customer support section">
                    <FloatButton
                        shape="square"
                        type="default"
                        description="Support"
                        style={{
                            right: 24,
                            bottom: 160,
                            width: '60px',
                            height: '60px'
                        }}
                        onClick={() => navigate('/customer-support/ticket-management')}
                        icon={<CustomerServiceOutlined />}
                    />
                </Tooltip>
            )}
            {/* </FloatButton.Group> */}

            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: mode === 'dark' ? '#1f1f1f' : theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                    zIndex: zinde
                }}
            >
                <Toolbar>
                    <Header premiumStatus={isPremiumBc} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            {/* drawer */}
            <Sidebar
                premiumStatus={isPremiumBc}
                isEmployee={isEmployee}
                drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} mode={mode}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}

            <Modal
                title={<Chip color="info" label={'How can we help you?'} sx={{}} />}
                className="custom-modal2"
                wrapClassName="custom-modal-backdrop"
                centered
                open={faqModalOpen}
                onOk={() => setFaqModalOpen(false)}
                onCancel={() => setFaqModalOpen(false)}
                width={900}
                footer={null}
                // closable={false}
                maskClosable={false}
            >
                <Search size="large" onChange={handleInputChange} placeholder="Ask here anything you want to know." />
                <>
                    {faqRes?.map((item, index) => {
                        const panelId = `panel${index + 1}`;
                        return (
                            <Accordion key={index} expanded={expanded === panelId} onChange={handleChange(panelId)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={index + 1}>
                                    <Typography>{item?.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{item?.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            </Modal>
            <Modal open={modalForSession} closable={false} footer={null} centered={true}>
                <div style={{ textAlign: 'center' }}>
                    {/* <h2>EKYC Completed successfully</h2> */}
                    <h3>{messageExp}</h3>
                    <MyButton variant="text" onClick={handleOk}>
                        You have been logged out. Redirecting in {timeLeft} seconds.
                    </MyButton>
                </div>
            </Modal>
        </Box>
    );
};

export default MainLayout;
