/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { Router, useNavigate } from 'react-router';
import { globalNotifyError } from 'views/utilities/AlertToast';
import { logoutFunctionGlobal } from './useLogout';
import { supportAuthApi } from './API/Merchant APIs/AuthApiCalls';

let count = 0;

// const { handleLogout } = useLogout();
export const handleCatchError = (catchError) => {
    console.log('catchError :', catchError);
    if (catchError?.code === 'ERR_NETWORK') {
        globalNotifyError('Oops..! A network error occured.');
    }
    if (catchError?.response?.status === 429) {
        //CONDITION TO HANDLE 429 CODE ERRORS
        const message = catchError?.response?.data?.data?.message;
        globalNotifyError(message);
    } else if (catchError?.response?.status === 500) {
        globalNotifyError('Something went wrong, Please try again !');
    } else if (catchError?.response?.status === 406) {
        if (count === 0) {
            // console.log('count', count);
            globalNotifyError('Authentication expired, Please login again.');
            count++;
        }
        const timerId = setTimeout(logoutFunctionGlobal, 700); // Call myFunction after 1 seconds (2000 milliseconds)
        // Clean up the timer when the component unmounts

        return () => clearTimeout(timerId);
    } else if (catchError?.response?.status === 451) {
        // globalNotifyError('Session timeout')
        handleTokenSwap();
    } else if (catchError?.response?.status === 401) {
        console.log('401 catch error');
        getSupportToken();
    } else if (catchError?.response?.status === 404) {
        globalNotifyError('Oops..! Something went wrong.');
    } else if (catchError?.response?.status === 408) {
        const message = catchError?.response?.data?.message;
        localStorage.setItem('tokenMessage', message);
        handleForgotPasswordTokenExp();
    }
};

export const handleTokenSwap = () => {
    const mpinStatus = true;
    localStorage.setItem('mpinStatus', mpinStatus);
    const PublicMessageStatus = false;
    localStorage.setItem('PublicMessageStatus', PublicMessageStatus);
};

//Function to reauthenticate support token
const getSupportToken = async () => {
    console.log('api function');
    // setRaiseLoading(true);
    const id = localStorage.getItem('id');
    try {
        const payload = { id };
        const response = await supportAuthApi(payload);
        console.log('support auth response:', response);
        // setRaiseLoading(false);
        // navigate('/merchant-home');

        if (response?.data?.success) {
            const sup = response?.data?.data;
            const platform = response?.data?.platform;
            localStorage.setItem('supportToken', sup);
            localStorage.setItem('platform', platform);
            // if (message) {
            //     globalNotifySuccess(message);
            // }
        } else {
            const message = response?.data?.message;
            if (message) {
                globalNotifyError(message);
            }
            if (response?.data?.errors) {
                const errors = response?.data?.errors;
                // Get an array of all values from the errors object
                const errorArrays = Object.values(errors);
                // Access the first array by default
                const firstArray = errorArrays[0];
                // console.log('firstArray', firstArray);
                globalNotifyError(firstArray[0]);
            }
        }
    } catch (error) {
        // setRaiseLoading(false);
        console.log(error.message);
        handleCatchError(error);
    }
};
export const handleForgotPasswordTokenExp = () => {
    const passwordTokenExp = true;
    localStorage.setItem('passwordTokenExp', passwordTokenExp);
};
